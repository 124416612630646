.tree-wrapper {
	display: flex;
	flex-direction: column;
	list-style: none;
	padding: 0;
	margin: 1rem 0 0;
	align-items: center;
	position: relative;
	width: 100%;
	> div {
		outline: none;
		&:focus-visible {
			border-radius: .25rem;
			box-shadow: 0 0 0 .2rem rgba(49,132,253,.4);
		}
	}
}

.search {
	min-width: 290px;
}

.tree-item {
  display: flex !important;
	text-align: left;
	cursor: pointer;
	position: relative;
	border: 1px solid #ced4da !important;
	border-radius: 0.25rem;
	margin-top: 0 !important;
	margin-bottom: 0.3rem;
	padding-left: 0.5rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.icon {
	margin-right: 0.75rem;
	height: 1rem;
	width: 1rem;
margin-top: 4px;
}
