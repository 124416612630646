.searchInputs {
  display: flex;
}
@media screen and (max-width: 768px) {
  .search input {
    width: 200px;
    padding: 5px;

  }
}
@media screen and (min-width: 769px) {
  .search input {
    width: 300px;
    padding: 15px;
  }
}
.search input {
  background-color: white;
  border: 0;
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 40px;
}


.searchIcon {
  display: grid;
  place-items: center;
}

input:focus {
  outline: none;
}


.dataResult {
  margin-top: 5px;
  width: 300px;
  max-height: 200px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  padding: 5px;
  position: absolute;
  z-index: 4;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  display: flex;
  align-items: center;
  color: rgb(8, 8, 8);
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 5px;
}

.dataItem:hover {
  color: rgb(114, 88, 18);
  font-weight: 700;
}

.dataItem p {
  margin-left: 10px;
  margin-bottom: 0px;
}

#clearBtn {
  cursor: pointer;
}

.searchBtn {
  cursor: pointer;
}
