* {
    box-sizing: border-box;
  }
  
 
  p {
    margin-top: 0px;
  }
  
  .bodyClass {
    background-color: #fff;
    padding: 100px;
  }
  
  .menuClass {
    background-color: rgb(242, 204, 89);
    opacity: 0.9;
    padding: 15px;
    z-index: 1000;
    overflow: scroll;
    height: 100vh;

  }
