:root {
  --color-1: #FFC300;
  --color-2: #e12454;
  --color-3: #0050a4;
  --color-4: #2c2c2c;
  --color-5: #b88f08;
  --color-6: #5e4c11;

  --color-white: #fffefd;
  --color-sub: #d14509;
  --color-main-light: #ebebbd;
    --color-sub-light: #51546e;
  --color-sub-lighter: #ededfd;
}
