@import url("_variables.css");
body {
  background-color: #f2f7ff !important;
  overflow-x: hidden !important;
  font-size: 18px;
  color: rgb(88, 49, 3);
}
a {
  text-decoration: none;
}
.card {
  margin-bottom: 1.2rem;
  border: none;
}
.card .card-header {
  border: none;
}
.card .card-header h4 {
  font-size: 1.2rem;
  font-weight: 700;
}
.card .card-header ~ .card-body {
  padding-top: 0;
}
.card .card-content {
  position: relative;
}
.card .card-title {
  font-size: 1.2rem;
}
.card .card-body {
  padding: 1.5rem;
}
#sidebar.active .sidebar-wrapper {
  left: 0;
}

.burger-btn {
  display: none;
}

#main {
  margin-left: 300px;
  padding-left: 1rem;
}

@media screen and (max-width: 1199px) {
  #main {
    margin-left: 0;
  }
}

.text-black {
  color: #000;
}

a {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  color: #000;
}

a:hover,
a:active,
a:focus {
  color: var(--color-3);
  outline: none;
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

#busy-holder {
  background: transparent;
  width: 100%;
  height: 100%;
}

#busy-holder:before {
  position: absolute;
  content: "";
  width: 100%;
  background: #ffffff;
  height: 100%;
  left: 300;
  z-index: 999;
  opacity: 80%;
}

.busy {
  position: absolute;
  left: 50%;
  top: 35%;
  z-index: 1000;
  height: 200px;
  width: 200px;
}

  .text-sm {
    font-size: 14px;
  }

  .text-mobile {
    font-size: 28px;
  }
  .text-mobile-sm {
    font-size: 18px;
  }

  .text-me {
    font-size: 1.2rem;
  }

  .text-md {
    font-size: 2.25rem;
  }

  .text-lg {
    font-size: 3.75rem;
  }


.text-color {
  color: var(--color-1);
}

.text-color-2 {
  color: var(--color-2);
}

.text-color-3 {
  color: var(--color-3);
}

.text-color-5 {
  color: var(--color-5);
}

.bg-color-1 {
  background-color: var(--color-1);
}

.bg-color-5 {
  background-color: var(--color-5);
}

#fhst-counter {
  position: relative;
  clear: both;
  margin-bottom: 5em;
}

.fhst-counters {
  padding: 3em 0;
  background-size: auto;
  background-attachment: fixed;
  margin-left: -20px;
}

.fhst-counters .overlay {
  z-index: 0;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.fhst-counters .icon {
  width: 100px;
  height: 100px;
  display: table;
  margin: 0 auto;
  margin-bottom: 20px;
}

.fhst-counters .icon i {
  display: table-cell;
  vertical-align: middle;
  width: 100px;
  height: 100px;
  font-size: 50px;
  color: var(--color-1);
  background: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.fhst-counters .counter-wrap {
  border: 1px solid red !important;
}

.fhst-counters .fhst-counter {
  font-size: 40px;
  display: block;
  color: white;
  font-family: "Quicksand", Arial, sans-serif;
  width: 100%;
  font-weight: 700;
  margin-bottom: 0.3em;
}

.fhst-counters .fhst-counter-label {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 5px;
  margin-bottom: 2em;
  display: block;
  font-weight: 900;
}

.boxer {
  display: inline-flex;
  border-radius: 10%;
  text-align: center;
  vertical-align: middle;
  position: relative;
}

.boxer img {
  width: 90px;
  height: 90px;
  border-radius: 10%;
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  z-index: 2;
  color: #000;
  background-color: #fafaf9;
}

.w-40 {
  max-width: 40%;
}

.pagination {
  padding: 0;
}

.pagination .page-item {
  margin: 0px;
  padding: 0px;
}

.pagination .page-item .page-link {
  color: #000;
  background: transparent;
  color: #000;
  margin: 2px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border-color: var(--color-1);
}

.pagination .page-item .page-link:first-child {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
}

.pagination .page-item .page-link:hover,
.pagination .page-item .page-link:focus {
  background: var(--color-1);
  color: #fff;
  border: 1px solid var(--color-1);
}

@media screen and (min-width: 730px) {
  .pagination .page-item .page-link {
    padding: 10px 20px;
    font-size: small;
  }
}

@media screen and (max-width: 730px) {
  .pagination .page-item .page-link {
    padding: 1px 4px;
    font-size: small;
  }
}

.pagination .page-item.active .page-link {
  background: var(--color-1);
  border: 1px solid var(--color-1) !important;
}

.pagination .page-item.active .page-link:hover,
.pagination .page-item.active .page-link:focus {
  background: var(--color-1);
  color: #fff;
  border: 1px solid var(--color-1) !important;
}

.blog-entry {
  width: 100%;
  float: left;
  background: #fff;
  margin-bottom: 3em;
}

@media screen and (max-width: 768px) {
  .blog-entry {
    margin-bottom: 3em;
  }
}

.blog-entry .blog-img {
  width: 100%;
  float: left;
  overflow: hidden;
  position: static;
  z-index: 1;
  margin-bottom: 25px;
}

.blog-entry .blog-img img {
  position: relative;
  max-width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.blog-entry .desc h3 {
  font-size: 16px;
  margin-bottom: 25px;
  line-height: auto;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.blog-entry .desc h3 a {
  color: #000;
  text-decoration: none;
}

.blog-entry .desc span {
  display: block;
  margin-bottom: 20px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4) !important;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 400;
}

.blog-entry .desc span small i {
  color: #999999;
}

.blog-entry .desc .lead {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #000;
}

.blog-entry:hover .blog-img img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.form-control {
  color: var(--color-5);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
  border: transparent;
  background: #ecece4;
  height: 40px;
  font-size: 12px;
  font-weight: 500;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  margin-bottom: 10px;
}

.form-control:active,
.form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: transparent;
  background: #ebe383;
}

.form-group label {
  color: var(--color-4);
  font-size: small;
  margin-top: 5px;
}

.invalid {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.btn.btn-primary:disabled {
  background-color: gray;
  border: none;
}

.error-text {
  color: #b40e0e;
}

.btn {
  margin-left: 2px;
  margin-right: 4px;
  margin-bottom: 4px;
  font-family: "Quicksand", Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 8px 15px !important;
  height: 40px;
}

.btn-sm {
  padding: 4px 15px !important;
}

.btn.btn-md {
  padding: 8px 20px !important;
}

.btn.btn-lg {
  padding: 18px 36px !important;
}

.btn:hover,
.btn:active,
.btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary {
  background: var(--color-5);
  color: #fff;
  border: none;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background: var(--color-1) !important;
  border-color: var(--color-1) !important;
}

.btn-primary.btn-outline {
  background: transparent;
  color: var(--color-1);
  border: 2px solid var(--color-1);
}

.btn-primary.btn-outline:hover,
.btn-primary.btn-outline:focus,
.btn-primary.btn-outline:active {
  background: var(--color-1);
  color: #fff;
  height: 10px;
}

.btn-success {
  background: #5cb85c;
  color: #fff;
  border: 2px solid #5cb85c;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active {
  background: #4cae4c !important;
  border-color: #4cae4c !important;
}

.btn-success.btn-outline {
  background: transparent;
  color: #5cb85c;
  border: 2px solid #5cb85c;
}

.btn-success.btn-outline:hover,
.btn-success.btn-outline:focus,
.btn-success.btn-outline:active {
  background: #5cb85c;
  color: #fff;
}

.btn-info {
  background: #5bc0de;
  color: #fff;
  border: 2px solid #5bc0de;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active {
  background: #46b8da !important;
  border-color: #46b8da !important;
}

.btn-info.btn-outline {
  background: transparent;
  color: #5bc0de;
  border: 2px solid #5bc0de;
}

.btn-info.btn-outline:hover,
.btn-info.btn-outline:focus,
.btn-info.btn-outline:active {
  background: #5bc0de;
  color: #fff;
}

.btn-warning {
  background: #f0ad4e;
  color: #fff;
  border: 2px solid #f0ad4e;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active {
  background: #eea236 !important;
  border-color: #eea236 !important;
}

.btn-warning.btn-outline {
  background: transparent;
  color: #f0ad4e;
  border: 2px solid #f0ad4e;
}

.btn-warning.btn-outline:hover,
.btn-warning.btn-outline:focus,
.btn-warning.btn-outline:active {
  background: #f0ad4e;
  color: #fff;
}

.btn-danger {
  background: #d9534f;
  color: #fff;
  border: 2px solid #d9534f;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active {
  background: #d43f3a !important;
  border-color: #d43f3a !important;
}

.btn-danger.btn-outline {
  background: transparent;
  color: #d9534f;
  border: 2px solid #d9534f;
}

.btn-danger.btn-outline:hover,
.btn-danger.btn-outline:focus,
.btn-danger.btn-outline:active {
  background: #d9534f;
  color: #fff;
}

.btn-outline {
  background: none;
  border: 2px solid gray;
  font-size: 16px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media screen and (min-width: 769px) {
  .fhst-heading {
    color: var(--color-5);
    font-size: 38px;
    margin-bottom: 1em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 5px;
    line-height: 1.8;
    position: relative;
    text-decoration: underline;
    text-decoration-color: var(--color-1);
    text-underline-offset: 15px;
  }
}

@media screen and (max-width: 768px) {
  .fhst-heading {
    color: var(--color-5);
    font-size: 18px;
    margin-bottom: 1;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 5px;
    line-height: 1.8;
    position: relative;
    text-decoration: underline;
    text-decoration-color: var(--color-1);
    text-underline-offset: 10px;
  }
}

.fhst-heading2 {
  color: var(--color-5);
  font-size: 36px;
  margin-bottom: 0.6em;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 1.8;
  position: relative;
  text-decoration: underline;
  text-decoration-color: var(--color-1);
  text-underline-offset: 15px;
}

.fhst-heading3 {
  color: var(--color-6);
  font-size: 18px;
  margin-bottom: 0.8em;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 1.8;
  position: relative;
  text-decoration: underline;
  text-decoration-color: var(--color-1);
  text-underline-offset: 10px;
}

.fhst-heading span {
  display: block;
}

.heading-meta {
  margin-bottom: 15px;
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  color: #999999;
  font-weight: 500;
  letter-spacing: 5px;
}

.fhst-nav-toggle {
  cursor: pointer;
  text-decoration: none;
  background-color: #f2f7ff;
  border-radius: 30px;
}

.fhst-nav-toggle.active i::before,
.fhst-nav-toggle.active i::after {
  background: rgb(0, 0, 0);
}

.fhst-nav-toggle.dark.active i::before,
.fhst-nav-toggle.dark.active i::after {
  background: #000;
}

.fhst-nav-toggle:hover,
.fhst-nav-toggle:focus,
.fhst-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}

.fhst-nav-toggle i {
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  width: 30px;
  height: 2px;
  color: #000;
  font: bold 14px/0.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #000;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  /* opacity: 0.3; */
}

.fhst-nav-toggle i::before,
.fhst-nav-toggle i::after {
  content: "";
  width: 30px;
  height: 2px;
  background: #000;
  position: absolute;
  left: 0;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.fhst-nav-toggle.dark i {
  position: relative;
  color: #000;
  background: #000;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.fhst-nav-toggle.dark i::before,
.fhst-nav-toggle.dark i::after {
  background: #000;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.fhst-nav-toggle i::before {
  top: -7px;
}

.fhst-nav-toggle i::after {
  bottom: -7px;
}

.fhst-nav-toggle:hover i::before {
  top: -10px;
}

.fhst-nav-toggle:hover i::after {
  bottom: -10px;
}

.fhst-nav-toggle.active i {
  background: transparent;
}

.fhst-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.fhst-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.fhst-nav-toggle {
  position: fixed;
  left: -4px;
  top: 0px;
  z-index: 9999;
  cursor: pointer;
  opacity: 1;
  visibility: hidden;
  padding: 20px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

@media screen and (max-width: 768px) {
  .fhst-nav-toggle {
    opacity: 1;
    visibility: visible;
  }
}
.fhst-nav-toggle {
  opacity: 1;
  visibility: visible;
}
@media screen and (max-width: 480px) {
  .col-xxs-12 {
    float: none;
    width: 100%;
  }
}

.fhst-bg-color {
  background: #fafafa;
  position: relative;
}

.fhst-narrow-content {
  padding: 0 2.5em;
}

@media screen and (max-width: 768px) {
  .fhst-narrow-content {
    padding: 0 1em;
  }
}

.fhst-lead {
  font-size: 18px;
  line-height: 1.5;
}

.btn-learn {
  padding: 15px 30px !important;
  font-size: 13px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}

.page-title {
  width: 100%;
  margin-bottom: 30px;
}
.mt-50{
  margin-top: 50px;
}
.text-justify {
  text-align: justify;
}

.btn-facebook {
  border-radius: 5px;
  color: #3b5998;
  font-weight: bold;
}
.twt-btn {
  color: rgb(101, 154, 204);
  font-weight: bold;
  border-radius: 5px;
  margin: 10px;
}

.whtsp-btn {
  color: rgb(44, 183, 66);
  font-weight: bold;
  border-radius: 5px;
}

.btn-facebook:active,
.btn-facebook:hover,
.whtsp-btn:active,
.whtsp-btn:hover,
.twt-btn:active,
.twt-btn:hover {
  color: rgb(39, 29, 126);
}

.toggler a {
  color: white;
}

.cursor-pointer {
  cursor: pointer;
}

#fhst-page {
  width: 100%;
  overflow: hidden;
  position: relative;
}

#fhst-aside {
  width: 300px;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  overflow-y: hidden;
  z-index: 1001;
  background: #2c2c2c;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
#fhst-aside-mobile {
  width: 300px;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  overflow-y: hidden;
  z-index: 1001;
  background: #2c2c2c;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
@media screen and (max-width: 1200px) {
  #fhst-aside {
    width: 30%;
  }
}
@media screen and (max-width: 768px) {
  #fhst-aside {
    width: 270px;
    -moz-transform: translateX(-270px);
    -webkit-transform: translateX(-270px);
    -ms-transform: translateX(-270px);
    -o-transform: translateX(-270px);
    transform: translateX(-270px);
    padding-top: 4em;
  }
}

#fhst-aside #fhst-main-menu ul {
  margin-top: 10px;
  text-align: left;
  margin-left: 20px;
  padding: 0;
}
@media screen and (max-width: 768px) {
  #fhst-aside #fhst-main-menu ul {
    margin: 10px 0 2em 10px;
  }
}
#fhst-aside #fhst-main-menu ul li {
  margin: 0 0 10px 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}
#fhst-aside-mobile #fhst-main-menu ul li {
  margin: 0 0 10px 0;
  padding: 0;
  list-style: none;
  font-size: 20px !important;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}
#fhst-aside #fhst-main-menu ul li a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  position: relative;
  padding: 10px 0;
  font-family: "Quicksand", Arial, sans-serif;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
#fhst-aside #fhst-main-menu ul li a:after {
  content: "";
  position: absolute;
  height: 1px;
  bottom: 7px;
  left: 0;
  right: 0;
  background-color: #ffc300;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
#fhst-aside #fhst-main-menu ul li a:hover {
  text-decoration: none;
  color: white;
}
#fhst-aside #fhst-main-menu ul li a:hover:after {
  visibility: visible;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
}
#fhst-aside #fhst-main-menu ul li.fhst-active a {
  color: #ffc300;
  margin-left: 20px;
}

#fhst-aside #fhst-main-menu ul li.fhst-active a:after {
  visibility: visible;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
}

#fhst-aside .fhst-footer ul li a:hover,
#fhst-aside .fhst-footer ul li a:active,
#fhst-aside .fhst-footer ul li a:focus {
  text-decoration: none;
  outline: none;
  color: #ffc300;
}
 /* ------------------------------------- */



 #fhst-aside-mobile {
  width: 270px;
  -moz-transform: translateX(-270px);
  -webkit-transform: translateX(-270px);
  -ms-transform: translateX(-270px);
  -o-transform: translateX(-270px);
  transform: translateX(-270px);
  padding-top: 4em;
}


#fhst-aside-mobile #fhst-main-menu ul {
margin-top: 10px;
text-align: left;
margin-left: 20px;
padding: 0;
}


#fhst-aside-mobile #fhst-main-menu ul {
  margin: 10px 0 2em 10px;
}

#fhst-aside-mobile #fhst-main-menu ul li {
margin: 0 0 10px 0;
padding: 0;
list-style: none;
font-size: 12px;
font-weight: 500;
text-transform: uppercase;
letter-spacing: 1px;
}
#fhst-aside-mobile #fhst-main-menu ul li a {
color: rgba(255, 255, 255, 0.5);
text-decoration: none;
position: relative;
padding: 10px 0;
font-family: "Quicksand", Arial, sans-serif;
-webkit-transition: 0.3s;
-o-transition: 0.3s;
transition: 0.3s;
}
#fhst-aside-mobile #fhst-main-menu ul li a:after {
content: "";
position: absolute;
height: 1px;
bottom: 7px;
left: 0;
right: 0;
background-color: #ffc300;
visibility: hidden;
-webkit-transform: scaleX(0);
-moz-transform: scaleX(0);
-ms-transform: scaleX(0);
-o-transform: scaleX(0);
transform: scaleX(0);
-webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
-moz-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
-ms-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
-o-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
#fhst-aside-mobile #fhst-main-menu ul li a:hover {
text-decoration: none;
color: white;
}
#fhst-aside-mobile #fhst-main-menu ul li a:hover:after {
visibility: visible;
-webkit-transform: scaleX(1);
-moz-transform: scaleX(1);
-ms-transform: scaleX(1);
-o-transform: scaleX(1);
transform: scaleX(1);
}
#fhst-aside-mobile #fhst-main-menu ul li.fhst-active a {
color: #ffc300;
margin-left: 20px;
}
#fhst-aside-mobile #fhst-main-menu ul li.fhst-active a:after {
visibility: visible;
-webkit-transform: scaleX(1);
-moz-transform: scaleX(1);
-ms-transform: scaleX(1);
-o-transform: scaleX(1);
transform: scaleX(1);
}

#fhst-aside-mobile .fhst-footer ul li a:hover,
#fhst-aside-mobile .fhst-footer ul li a:active,
#fhst-aside-mobile .fhst-footer ul li a:focus {
text-decoration: none;
outline: none;
color: #ffc300;
}
 /* --------------------------------------- */
@media screen and (max-width: 768px) {
  #fhst-main-menu ul {
    margin: 0 0 2em 0;
  }
}

#fhst-main-menu ul li {
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

#fhst-main-menu ul li span {
  color: rgba(191, 101, 16, 0.8);
  text-decoration: none;
  position: relative;
  padding: 10px 0;
  font-family: "Quicksand", Arial, sans-serif;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-size: 16px;
  cursor: pointer;
}

#fhst-main-menu ul li span:after {
  content: "";
  position: absolute;
  height: 1px;
  bottom: 7px;
  left: 0;
  right: 0;
  background-color: #ffc300;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

#fhst-main-menu ul li span:hover {
  text-decoration: none;
  color: var(--color-1);
}

#fhst-main-menu ul li span:hover:after {
  visibility: visible;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
}

#fhst-main-menu ul li.fhst-active span:after {
  visibility: visible;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
}

.project {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
  margin-bottom: 30px;
}

.project .desc {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.project .desc .con {
  padding: 20px;
  display: block;
  position: relative;
  height: 300px;
}

.project .desc .con .icon {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

.project .desc .con .icon span {
  display: inline;
  padding-right: 5px;
}

.project .desc .con .icon span a {
  color: #cccccc;
  padding: 5px;
  background: #333333;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

.project .desc .con .icon i {
  font-size: 16px;
  color: #cccccc;
}

.project .desc h3 {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  -o-transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  -webkit-transform: translate3d(0, -15px, 0);
  transform: translate3d(0, -15px, 0);
  margin-bottom: 15px;
}

.project .desc h3 a {
  color: #fff;
}

.project .desc span {
  display: block;
  color: #999999;
  font-size: 13px;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  -o-transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  -webkit-transform: translate3d(0, 15px, 0);
  transform: translate3d(0, 15px, 0);
}

.project .desc span a {
  color: #000;
}

@media screen and (max-width: 768px) {
  .project .desc {
    opacity: 1;
    background: rgba(0, 0, 0, 0.4);
  }

  .project .desc h3 {
    font-style: 28px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    color: #000;
  }

  .project .desc span {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.project:hover .desc {
  opacity: 1;
}

.project:hover .desc h3 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.project:hover .desc span {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.project-desc h2 {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

.project-desc span {
  display: block;
  color: #666666;
  font-size: 13px;
  margin-bottom: 15px;
}

.project-desc span a {
  color: #666666;
}

.project-desc .icon span {
  display: inline;
  padding-right: 5px;
}

.project-desc .btn-download {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: underline;
}

.fancy-collapse-panel .panel-default > .panel-heading {
  padding: 0;
}

.accordion-item .accordion-header {
  padding: 15px 25px;
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  background-color: #f0f0f0;
  position: relative;
  border: 1px solid #e6e6e6 !important;
}

.accordion-item .active {
  background-color: var(--color-4);
}

.accordion-item .active button {
  color: white;
  font-size: 24px;
}

.accordion {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
}

.accordion-header button {
  background-color: transparent;
  border: 0;
  width: 100%;
  font-weight: 400;
  outline: 0px;
}

.accordion-header button:after {
  content: "\21E7";
  position: absolute;
  right: 20px;
  font-size: 20px;
  font-weight: 400;
  top: 50%;
  line-height: 1;
  margin-top: -10px;
}

.accordion-header button.collapsed:after {
  content: "\21E9";
}

.accordion-body {
  padding: 1.5em;
  margin-bottom: 5px;
  border: 1px solid #e6e6e6 !important;
}

.tag {
  display: inline-flex;
  align-items: center;
  background-color: var(--color-1);
  color: var(--color-4);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.1rem 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;
}

.tag:not(:last-child) {
  margin-right: 0.25rem;
  margin-bottom: 0.75rem;
}

.tag--pill {
  border-radius: 0.6rem;
}

.tag--main,
.tag--main > small {
  background: var(--color-main-light);
  color: var(--color-main);
  font-weight: 500;
}

.catalog-header {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif !important;
  font-size: medium;
  margin-right: 10px;
}

.catalog-header .catalog-header-s1 {
  border: 1px solid #ddb42b !important;
  border-radius: 5px;
  padding: 3px;
  margin: 5px;
}

.catalog-header .catalog-header-s2 {
  color: var(--color-5);
  align-items: flex-start;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#fhst-hero {
  min-height: 500px;
  background: #fff;
  width: 100%;
  float: left;
  margin-bottom: 2em;
  clear: both;
}

#fhst-hero .flexslider {
  border: none;
  z-index: 1;
  margin-bottom: 0;
}

#fhst-hero .flexslider .slides {
  position: relative;
  overflow: hidden;
}

#fhst-hero .flexslider .slides li {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  min-height: 400px;
  position: relative;
}

#fhst-hero .flexslider .flex-control-nav {
  bottom: 20px;
  z-index: 1000;
  left: 20px;
  float: left;
  width: auto;
}

@media screen and (max-width: 768px) {
  #fhst-hero .flexslider .flex-control-nav {
    right: 20px;
    top: 100px;
  }
}

#fhst-hero .flexslider .flex-control-nav li {
  display: block;
  margin-bottom: 10px;
}

#fhst-hero .flexslider .flex-control-nav li a {
  background: rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

#fhst-hero .flexslider .flex-control-nav li a.flex-active {
  cursor: pointer;
  background: transparent;
  border: 2px solid #ffc300;
}

#fhst-hero .flexslider .flex-direction-nav {
  display: none;
}

#fhst-hero .flexslider .slider-text {
  display: table;
  opacity: 0.6;
  min-height: 500px;
  z-index: 9;
}

#fhst-hero .flexslider .slider-text > .slider-text-inner {
  display: table-cell;
  vertical-align: middle;
  min-height: 700px;
  padding: 2.5em;
  position: relative;
}

#fhst-hero .flexslider .slider-text > .slider-text-inner .desc {
  position: absolute;
  bottom: 0;
  right: 20px;
  padding: 30px;
  /* background: #252522; */
}

@media screen and (max-width: 768px) {
  #fhst-hero .flexslider .slider-text > .slider-text-inner .desc {
    right: 0;
    left: 0;
  }
}

@media screen and (max-width: 768px) {
  #fhst-hero .flexslider .slider-text > .slider-text-inner {
    text-align: center;
  }
}

/* #fhst-hero .flexslider .slider-text > .slider-text-inner .heading-section {
  font-size: 50px;
} */

@media screen and (max-width: 768px) {
  #fhst-hero .flexslider .slider-text > .slider-text-inner .heading-section {
    font-size: 30px;
  }
}


body.offcanvas {
  overflow-x: hidden;
}

body.offcanvas #fhst-aside {
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  width: 270px;
  background: #2c2c2c;
  z-index: 999;
  position: fixed;
}
body.offcanvas #fhst-aside-mobile {
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  width: 270px;
  background: #2c2c2c;
  z-index: 999;
  position: fixed;
}
body.offcanvas #fhst-main,
body.offcanvas .fhst-nav-toggle {
  top: 0;
  -moz-transform: translateX(270px);
  -webkit-transform: translateX(270px);
  -ms-transform: translateX(270px);
  -o-transform: translateX(270px);
  transform: translateX(270px);
}

.fhst-narrow-content {
  padding: 0 2.5em;
}

@media screen and (max-width: 768px) {
  .fhst-narrow-content {
    padding: 0 1em;
  }
}

.flexslider {
  margin: 0 0 60px;
  background: #ffffff;
  border: 4px solid #ffffff;
  position: relative;
  zoom: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
}
.flexslider .slides {
  zoom: 1;
}

.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
}
.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.flex-control-paging li a {
  width: 11px;
  height: 11px;
  display: block;
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.flex-control-paging li a:hover {
  background: #333;
  background: rgba(0, 0, 0, 0.7);
}
.flex-control-paging li a.flex-active {
  background: #000;
  background: rgba(0, 0, 0, 0.9);
  cursor: default;
}

.backtop {
  position: fixed;
  background: #a9b137;
  z-index: 9999;
  display: inline-block;
  right: 10px;
  width: 50px;
  height: 50px;
  bottom: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  border-radius: 50px;
}

.backtop i {
  color: #fff;
  font-size: 20px;
}

.reveal {
  transition: all 0.3s;
  cursor: pointer;
  opacity: 0.8;
}

.image-gallery-description {
  background: rgba(0, 0, 0, 0.4);
  bottom: 5px;
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  white-space: normal;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.image-gallery {
  width: 100%;
  margin-bottom: 30px;
}

.fhst-blog,
.fhst-work,
.fhst-about,
.fhst-services,
.fhst-contact {
  padding-top: 4em;
  padding-bottom: 9em;
  clear: both;
  width: 100%;
  display: block;
}
@media screen and (max-width: 768px) {
  .fhst-blog,
  .fhst-work,
  .fhst-about,
  .fhst-services,
  .fhst-contact {
    padding-top: 5em;
    padding-bottom: 8em;
  }
}

.fhst-feature {
  text-align: left;
  width: 100%;
  float: left;
  margin-bottom: 40px;
  position: relative;
}
.fhst-feature .fhst-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  display: table;
  text-align: center;
  background: rgba(0, 0, 0, 0.03);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
}
.fhst-feature .fhst-icon i {
  display: table-cell;
  vertical-align: middle;
  color: #ffc300;
  font-size: 60px;
  height: 100px;
}
@media screen and (max-width: 1200px) {
  .fhst-feature .fhst-icon i {
    font-size: 40px;
  }
}
.fhst-feature .fhst-text {
  padding-left: 120px;
  width: 100%;
}
.fhst-feature .fhst-text h2,
.fhst-feature .fhst-text h3 {
  margin: 0;
  padding: 0;
}
.fhst-feature .fhst-text h3 {
  font-weight: 500;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.fhst-feature.fhst-feature-sm .fhst-text {
  margin-top: 30px;
}
.fhst-feature.fhst-feature-sm .fhst-icon i {
  color: #ffc300;
  font-size: 40px;
}
@media screen and (max-width: 1200px) {
  .fhst-feature.fhst-feature-sm .fhst-icon i {
    font-size: 28px;
  }
}

.fhst-feature-lg .fhst-text h2,
.fhst-feature-lg .fhst-text h3 {
  margin: 0;
  padding: 0;
}

.fhst-feature-lg .fhst-text h3 {
  font-weight: 500;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.image-gallery-image {
  max-height: 500px !important;
}

.navbar-custom {
  height: 75px;
}

.navbar-toggler {
  border-width: 0;
  box-shadow: none;
}

.navbar-toggler-icon {
  background: #2c2c2c;
  border-radius: 5px;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  margin-left: 90% !important;
  outline: none;
  box-shadow: none;
  color: #000;
}

.nav-menu {
  background-color: #2c2c2c;
  opacity: 0.9;
  padding: 10px;
  font-size: 12px;
  border-radius: 10px;
  z-index: 100;
}

.img-fit {
  height: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.image-gallery-svg {
  color: var(--color-1);
}

.image-gallery-right-nav .image-gallery-svg {
  transform: scale(0.5);
}

.image-gallery-left-nav .image-gallery-svg {
  transform: scale(0.5);
}

#__react-alert__ div > div {
  width: 50% !important;
}
#__react-alert__ div > div > div {
  background-color: var(--color-1) !important;
  color: #000 !important;
  font-size: smaller;
  border-radius: 20px !important;
  width: 80% !important;
  box-shadow: 2px 2px #c7c6c4 !important;
}

.herb-active {
  color: #ffc300;
  margin-left: 20px;
}