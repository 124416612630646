.ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #f1f1ea;
  }
  
  /* Snow Theme */
 .ql-snow.ql-toolbar {
    display: block;
    background: #f2f0e4;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }
  

  .ql-editor {
    min-height: 10em;
    max-height: 30em;
    overflow: auto;
  }