.node-title {
    dx: -200;
    fill: green;
  }
  
  .node-label {
    color: red;
  }

  .treecontainer {
    font-family: sans-serif;
    text-align: center;
  }
  
  .node circle {
    fill: #fff;
    stroke: steelblue;
    stroke-width: 3px;
  }
  
  .node text { 
    font: 12px sans-serif; 
    align-items: left;
    border: 2px;
    border-color: blue;
  }
  
  .nodeleaf circle {
    fill: rgb(196, 214, 32);
    stroke: steelblue;
    stroke-width: 3px;
  }
  
  .nodeleaf text { font: 12px sans-serif; }
  
  .link {
    fill: none;
    stroke: #2c2c2c;
    stroke-width: 2px;
  }
  
  
  .rd3t-link {
    fill: none;
    stroke: #2593B8;
    stroke-width: 1.5px;
  }
  
  .rd3t-label {
    justify-content: left;
  }
  
  .rd3t-label__title{
    color:blue
  }